.wallet-ctn,
.coupon-ctn,
.gallery-ctn,
.club-offers-ctn,
.cashout,
.account-wrapper-ctn,
.v2-modal {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    font-family: Inter;
  }
}

.wallet-ctn,
.coupon-ctn,
.gallery-ctn,
.club-offers-ctn,
.cashout,
.account-wrapper-ctn,
.v2-modal {
  h6,
  th {
    font-family: InterBold;
    color: #212931;
  }
}
.account-wrapper-ctn,
.login-ctn,
.forgot-ctn,
.register-ctn {
  h5,
  p {
    font-family: InterMedium;
  }
}

@media (min-width: 721px) {
  .wallet-ctn,
  .coupon-ctn,
  .gallery-ctn,
  .club-offers-ctn,
  .cashout,
  .account-wrapper-ctn,
  .v2-modal {
    h1,
    h2,
    h3,
    h4 {
      font-weight: 300;
      color: #212931;
    }

    h1 {
      font-size: 3.2rem;
    }
    h2 {
      font-size: 2.9rem;
    }
    h3 {
      font-size: 2.6rem;
    }
    h4 {
      font-size: 2.3rem;
    }
  }
}

// Mobile & Ipad
@media screen and (max-width: 720px) {
  .wallet-ctn,
  .coupon-ctn,
  .gallery-ctn,
  .club-offers-ctn,
  .cashout,
  .account-wrapper-ctn,
  .v2-modal {
    h1,
    h2,
    h3,
    h4 {
      font-weight: 300;
      color: #212931;
    }

    h1 {
      font-size: 2.9rem;
    }
    h2 {
      font-size: 2.6rem;
    }
    h3 {
      font-size: 2.3rem;
    }
    h4 {
      font-size: 2rem;
    }
  }
}

// Button
.btn {
  color: white;
  outline: none;
  padding: 1.2rem 1.6rem;
  font-size: 16px;
  box-shadow: none;
  line-height: 1;
  background-color: #52b846 !important;
  text-transform: capitalize;
  margin-left: 5px;
  margin-top: 10px;

  &:hover {
    box-shadow: none;
    background-color: #52b846;
  }

  &:focus {
    box-shadow: none;
    background-color: #52b846;
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }
}

.MuiTab-textColorInherit.Mui-selected {
  color: #212931;
  font-family: 'InterBold';
}
